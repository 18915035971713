import {
  BlocksEmbeddedEntryProps,
  TopicSection,
} from '@modelberry/blue-lib/react'
import { getTopic } from '../lib/get-topic'
import { ContentfulList } from '../cms/contentful-list'
import { ContentfulPage } from '../cms/contentful-page'
import { ContentfulTopic } from '../cms/contentful-topic'
import { ContactForm } from './contact-form/contact-form'
import { topicVariantCss } from './topic-variant-css'

export const BlocksEmbeddedEntry = ({
  model,
  options,
  subComponents,
}: BlocksEmbeddedEntryProps) => {
  const entry = model?.links?.entries?.block.find(
    (e) => e.sys?.id === model?.node.data.target.sys.id
  )

  const typename = entry?.__typename
  if (typename === 'List') {
    const contentfulListEntry = entry as ContentfulList
    // A list containts either Page or List items
    const items = contentfulListEntry?.listCollection?.items
    const firstItem = items?.[0]

    if (firstItem?.__typename === 'Page') {
      /**
       * LIST OF PAGES
       */

      const contentfulPages: ContentfulPage[] = items || []
      const contentfulPageTopics = contentfulPages.map((page) => page.pageTopic)
      const topics = contentfulPageTopics.map((pageTopic, index) =>
        getTopic({
          contentfulTopic: pageTopic,
          createActionFromPage: contentfulPages[index],
          preview: options?.preview,
          useMetaDataFromPage: contentfulPages[index],
        })
      )
      return (
        <TopicSection
          anyCss={{ marginBottom: [24, 48] }}
          model={{ topics }}
          options={{
            reversedOrder: contentfulListEntry?.topicReversedOrder,
            useTextColumnWidth: contentfulListEntry.useTextColumnWidth,
          }}
          subComponents={subComponents}
          topicVariant={contentfulListEntry?.topicVariant}
          topicVariantCss={topicVariantCss}
          variant={contentfulListEntry?.variant}
        />
      )
    } else if (firstItem?.__typename === 'Topic') {
      /**
       * LIST OF TOPICS
       */

      const contentfulTopics: ContentfulTopic[] = items || []
      const topics = contentfulTopics.map((contentfulTopic) =>
        getTopic({
          contentfulTopic,
          preview: options?.preview,
        })
      )

      return (
        <TopicSection
          anyCss={{ marginBottom: [24, 48] }}
          model={{ topics }}
          options={{
            reversedOrder: contentfulListEntry?.topicReversedOrder,
            useTextColumnWidth: contentfulListEntry.useTextColumnWidth,
          }}
          subComponents={subComponents}
          topicVariant={contentfulListEntry?.topicVariant}
          topicVariantCss={topicVariantCss}
          variant={contentfulListEntry?.variant}
        />
      )
    } else return null
  } else if (typename === 'Topic') {
    /**
     * SINGLE TOPICS
     */

    const contentfulTopic = entry as ContentfulTopic
    const topic = getTopic({
      contentfulTopic,
      preview: options?.preview,
    })
    return (
      <TopicSection
        anyCss={{ marginBottom: [24, 48] }}
        model={{ topics: [topic] }}
        options={{
          reversedOrder: contentfulTopic?.reversedOrder,
          useTextColumnWidth: !contentfulTopic?.usePageWidth,
        }}
        subComponents={subComponents}
        topicVariant={contentfulTopic?.variant}
        topicVariantCss={topicVariantCss}
        variant="single"
      />
    )
  } else if (typename === 'Page') {
    /**
     * SINGLE PAGE
     */

    const contentfulPage = entry as ContentfulPage
    const contentfulPageTopic = contentfulPage?.pageTopic
    const pageTopic = getTopic({
      contentfulTopic: contentfulPageTopic,
      createActionFromPage: contentfulPage,
      preview: options?.preview,
    })

    return (
      <TopicSection
        anyCss={{ marginBottom: [24, 48] }}
        model={{ topics: [pageTopic] }}
        options={{
          reversedOrder: contentfulPageTopic?.reversedOrder,
          useTextColumnWidth: !contentfulPageTopic?.usePageWidth,
        }}
        subComponents={subComponents}
        topicVariant="card"
        topicVariantCss={topicVariantCss}
        variant="single"
      />
    )
  } else if (typename === 'ContactForm') {
    /**
     * CONTACT FORM
     */

    return (
      <ContactForm
        anyCss={{ marginBottom: [24, 48] }}
        model={{ contentfulContactForm: entry }}
        subComponents={subComponents}
      />
    )
  } else {
    console.log(`Block embed entry '${typename}' not implemented`)
    return null
  }
}
