import { gql } from '@apollo/client'
import { ContentfulAsset, IconName } from '@modelberry/blue-lib/react'
import { apolloActionFragment, ContentfulAction } from './contentful-action'
import { apolloAssetFragment } from './contentful-asset'
import { ContentfulPage } from './contentful-page'

export type TopicVariant =
  | 'block'
  | 'card'
  | 'featured'
  | 'headline'
  | 'hero'
  | 'media'
  | 'profile'
  | 'quote'
  | 'showcase'

/** @modelberry
 * - {@plugin "@modelberry/plugin-contentful/plain"}
 * - {@type topic}
 * - {@displayField title}
 * - {@description Topic}
 * - {@name topic}
 */
export interface ContentfulTopic {
  /** @modelberry {@ignore} */
  __typename?: string
  /** @modelberry {@ignore} */
  sys?: {
    id?: string
  }

  /** @modelberry
   * - {@name Titel binnen Contentful}
   * - {@required true}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Alleen gebruikt in Contentful, wordt nooit getoond op de site zelf}
   */
  title?: string

  /** @modelberry
   * - {@name Variant}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText Variant voor de topic. Topics in een lijst tonen niet deze variant, maar de variant van de lijst.}
   * - {@validations in-topic-variant}
   */
  variant?: TopicVariant

  /** @modelberry
   * - {@name icoon (zie feathericons.com)}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText Icoon bij het onderwerp}
   * - {@validations in-feather-icon}
   */
  icon?: IconName

  /** @modelberry
   * - {@name Titel}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Titel van het onderwerp, zonder punt aan eind}
   */
  heading?: string

  /** @modelberry
   * - {@name Korte tekst}
   * - {@type Text}
   * - {@widgetId multipleLine}
   * - {@helpText Korte omschrijving van het onderwerp, sluit af met een punt}
   */
  abstract?: string

  /** @modelberry
   * - {@name Media}
   * - {@type Link}
   * - {@linkType Asset}
   * - {@widgetId assetLinkEditor}
   * - {@helpText Afbeelding of video bij topic.}
   * - {@validations linkMimetypeGroup-image-video}
   */
  media?: ContentfulAsset

  /** @modelberry
   * - {@name Embed code}
   * - {@type Text}
   * - {@widgetId multipleLine}
   * - {@helpText HTML Embed code voor bijvoorbeeld Youtube video's}
   */
  embed?: string

  /** @modelberry
   * - {@name Pagina breedte}
   * - {@type Boolean}
   * - {@helpText Lijn uit met de breedte van de pagina in plaats van de breedte van de tekst.}
   */
  usePageWidth?: boolean

  /** @modelberry
   * - {@name Draai volgorde om}
   * - {@type Boolean}
   * - {@helpText Draai de volgorde om van tekst en afbeelding.}
   */
  reversedOrder?: boolean

  /** @modelberry
   * - {@name Actie of paginalink}
   * - {@type Array}
   * - {@itemsType Link}
   * - {@itemsLinkType Entry}
   * - {@itemsValidations linkContentType-action-page}
   * - {@widgetId entryLinksEditor}
   * - {@helpText Voeg een actie toe of maak een actie die linkt naar een pagina.}
   */
  actionsCollection?: {
    items: (ContentfulAction | ContentfulPage)[]
  }
}

export const apolloTopicFragment: any = gql`
  ${apolloActionFragment}
  ${apolloAssetFragment}
  fragment Modelberry_Topic on Topic {
    sys {
      id
    }
    variant
    icon
    heading
    abstract
    media {
      ...Modelberry_Asset
    }
    embed
    usePageWidth
    reversedOrder
    actionsCollection(limit: 2) {
      items {
        ...Modelberry_Action
        ... on Page {
          __typename
          sys {
            id
          }
          actionHeading
          path
          pageTopic {
            ... on Topic {
              heading
            }
          }
        }
      }
    }
  }
`
