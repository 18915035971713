import { Button, ButtonProps, StyleObject } from '@modelberry/blue-lib/react'

const buttonStyle: StyleObject = {
  backgroundColor: `var(--color-fancygreen-400)`,
  border: '1px solid var(--color-fancygreen-400)',
  fontFamily: 'var(--heading-font-family)',
  fontWeight: 500,
  ':active, :focus': {
    boxShadow: `0 0 6px var(--color-fancygreen-300)`,
  },
  ':hover': {
    backgroundColor: `var(--color-fancygreen-500)`,
    borderColor: `var(--color-fancygreen-500)`,
  },
}

export const GreenButton = ({ anyCss, ...props }: ButtonProps) => (
  <Button {...props} anyCss={[buttonStyle, anyCss as any]} />
)
