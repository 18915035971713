import { Global } from '@emotion/react'
import { Div, globalReset } from '@modelberry/any-element/react'
import { graphql, navigate } from 'gatsby'
import type { PageProps } from 'gatsby'
import { UpdateButton } from '@modelberry/blue-lib/react'
import { ApolloClient, ApolloConsumer } from '@apollo/client'
import { useEffect, useState } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import { ContentfulPage } from '../cms/contentful-page'
import { ContentfulGlobals } from '../cms/contentful-globals'
import { fetchPage } from '../graphql-client/fetch-page'
import { isPreviewMode } from '../lib/is-preview-mode'
import { isBrowser } from '../lib/is-browser'
import { getSegment } from '../lib/get-segment'

import {
  navigationSubComponents,
  richTextSubComponents,
} from '../components/sub-components'
import { PageMain } from './page-main'
import { PageMeta } from './page-meta'
import { HeaderSection } from '../components/header-section'
import { FooterSection } from '../components/footer-section'
import { getTopic } from '../lib/get-topic'

if (isBrowser) {
  smoothscroll.polyfill()
}

export type PageContext = {
  page?: ContentfulPage
  globals?: { items?: ContentfulGlobals[] }
}

export type PageData = {
  site: {
    siteMetadata: {
      siteBranding: string
      siteVersion: string
    }
  }
}

export type State = {
  preview?: boolean
}

export interface PageTemplateProps
  extends PageProps<PageData, PageContext, State> {
  client: ApolloClient<any>
}

const PageTemplate = ({
  data,
  pageContext,
  client,
  location,
}: PageTemplateProps) => {
  const [previewPage, setPreviewPage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const preview = isPreviewMode() || location.state?.preview
  useEffect(() => {
    if (preview) fetchPreview()
  }, [])
  const page = previewPage || pageContext.page
  const globals = pageContext.globals?.items?.[0]
  const actionSegment = getSegment({
    contentfulNavigationSegment: globals?.actionSegment,
    preview,
  })
  const menuSegment = getSegment({
    contentfulNavigationSegment: globals?.menuSegment,
    preview,
  })
  const socialSegment = getSegment({
    contentfulNavigationSegment: globals?.socialSegment,
    preview,
  })
  const contentfulSiteTopics = globals?.siteTopicsCollection?.items || []
  const siteTopics = contentfulSiteTopics.map((contentfulTopic) =>
    getTopic({
      contentfulTopic,
      preview,
    })
  )
  const fetchPreview = async () => {
    setIsLoading(true)
    const fetchedPage = await fetchPage({
      client,
      pageId: page?.sys?.id,
      preview: true,
    })
    setPreviewPage(fetchedPage)
    setIsLoading(false)
  }
  const hasLightBackground = page?.path === '/'
  return (
    <Div>
      {preview && (
        <UpdateButton options={{ loading: isLoading }} onClick={fetchPreview}>
          Update
        </UpdateButton>
      )}
      <Global styles={globalReset} />
      <PageMeta data={data} globals={globals} page={page} />
      <HeaderSection
        model={{
          actionSegment: actionSegment,
          brandingActionFn: () => navigate('/', { state: { preview } }),
          brandingHeading: data?.site?.siteMetadata?.siteBranding,
          menuSegment,
          socialSegment,
        }}
        options={{ preview, hasLightBackground }}
        subComponents={navigationSubComponents}
      />
      <PageMain
        model={{ page }}
        options={{ preview, hasLightBackground }}
        subComponents={richTextSubComponents}
      />
      <FooterSection
        model={{
          brandingHeading: data?.site?.siteMetadata?.siteBranding,
          menuSegment,
          siteTopics,
          siteVersion: data?.site?.siteMetadata?.siteVersion,
          socialSegment,
        }}
        subComponents={navigationSubComponents}
      />
    </Div>
  )
}

const PageWithApolloClient = (props: Omit<PageTemplateProps, 'client'>) => (
  <ApolloConsumer>
    {(client) => <PageTemplate client={client} {...props} />}
  </ApolloConsumer>
)

export default PageWithApolloClient

export const query: any = graphql`
  query {
    site {
      siteMetadata {
        siteBranding
        siteVersion
      }
    }
  }
`
