import { gql } from '@apollo/client'
import { ContentfulPage } from './contentful-page'
import {
  apolloTopicFragment,
  ContentfulTopic,
  TopicVariant,
} from './contentful-topic'

export type ListVariant =
  | 'one-collumn'
  | 'one-collumn-swap-media'
  | 'two-collumn'
  | 'three-collumn'
  | 'four-collumn'
  | 'carousel'
  | 'single'

/** @modelberry
 * - {@plugin "@modelberry/plugin-contentful/plain"}
 * - {@type list}
 * - {@displayField title}
 * - {@description List}
 * - {@name list}
 */
export interface ContentfulList {
  /** @modelberry {@ignore} */
  __typename?: string
  /** @modelberry {@ignore} */
  sys?: {
    id?: string
  }

  /** @modelberry
   * - {@name Titel binnen Contentful}
   * - {@required true}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Alleen gebruikt in Contentful, wordt nooit getoond op de site zelf}
   */
  title?: string

  /** @modelberry
   * - {@name Variant}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText In wat voor lijst de topics worden getoond.}
   * - {@validations in-list-variant}
   */
  variant?: ListVariant

  /** @modelberry
   * - {@name Topic variant}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText De weergave van de topics in de lijst.}
   * - {@validations in-topic-variant}
   */
  topicVariant?: TopicVariant

  /** @modelberry
   * - {@name Draai volgorde van alle topics om}
   * - {@type Boolean}
   * - {@helpText Draai de volgorde om van de tekst en afbeelding van alle topics in de lijst.}
   */
  topicReversedOrder?: boolean

  /** @modelberry
   * - {@name Dezelfde breedte als de tekst}
   * - {@type Boolean}
   * - {@helpText Lijn uit met de breedte van de tekst in plaats van de breedte van de pagina.}
   */
  useTextColumnWidth?: boolean

  /** @modelberry
   * - {@name Lijst met topics en/of pagina's}
   * - {@type Array}
   * - {@itemsType Link}
   * - {@itemsLinkType Entry}
   * - {@itemsValidations linkContentType-topic-page}
   * - {@widgetId entryLinksEditor}
   * - {@helpText Pagina's en/of topics die worden weergegeven}
   */
  listCollection?: {
    items: (ContentfulPage | ContentfulTopic)[]
  }
}

export const apolloListFragment: any = gql`
  ${apolloTopicFragment}
  fragment Modelberry_List on List {
    __typename
    sys {
      id
    }
    variant
    topicVariant
    topicReversedOrder
    useTextColumnWidth
    listCollection(limit: 50) {
      items {
        ...Modelberry_Topic
        ... on Page {
          __typename
          author
          actionHeading
          date
          label
          path
          pageTopic {
            ...Modelberry_Topic
          }
        }
      }
    }
  }
`
