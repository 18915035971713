import {
  Display,
  DisplayProps,
  Heading,
  HeadingProps,
  StyleObject,
} from '@modelberry/blue-lib/react'

const headingStyle: StyleObject = {
  color: 'var(--color-stone-500)',
}

export const StoneDisplay = ({ anyCss, ...props }: DisplayProps) => (
  <Display {...props} anyCss={[headingStyle, anyCss as any]} />
)

export const StoneHeading = ({ anyCss, ...props }: HeadingProps) => (
  <Heading {...props} anyCss={[headingStyle, anyCss as any]} />
)
