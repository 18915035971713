import {
  TextLink,
  TextLinkProps,
  StyleObject,
} from '@modelberry/blue-lib/react'

const blueLinkStyle: StyleObject = {
  color: 'var(--color-jeans-400)',
}

export const BlueTextLink = ({ anyCss, ...props }: TextLinkProps) => (
  <TextLink {...props} anyCss={[blueLinkStyle, anyCss as any]} />
)

const navLinkStyle: StyleObject = {
  color: 'inherit',
  fontFamily: 'var(--heading-font-family)',
  fontSize: 'var(--heading-font-size-m)',
  fontWeight: 500,
}

export const NavTextLink = ({ anyCss, ...props }: TextLinkProps) => (
  <TextLink {...props} anyCss={[navLinkStyle, anyCss as any]} />
)
