import { Div, Img } from '@modelberry/any-element/react'
import {
  ComponentProps,
  HeaderNavigation,
  mediaQuery,
  Navigation,
  SectionLayout,
} from '@modelberry/blue-lib/react'
import { NavTextLink } from './text-links'

export type HeaderSectionOptions = 'preview' | 'hasLightBackground'
export type HeaderSection = {
  actionSegment?: Navigation
  brandingActionFn: () => void
  brandingHeading?: string
  menuSegment?: Navigation
  socialSegment?: Navigation
}

export type HeaderSectionProps = ComponentProps<
  HeaderSection,
  string,
  HeaderSectionOptions
>['div']
export const HeaderSection = ({
  anyCss,
  model,
  options,
  subComponents,
  ...props
}: HeaderSectionProps) => {
  const actionCount = model?.actionSegment?.items?.length || 0
  const socialCount = model?.socialSegment?.items?.length || 0
  const menuCount = model?.menuSegment?.items?.length || 0
  if (actionCount + socialCount + menuCount <= 0) return null
  return (
    <Div
      anyCss={mediaQuery([
        { position: 'absolute', right: 0, zIndex: 10, width: '100%' },
        anyCss,
      ])}
      is="header"
      {...props}
    >
      <SectionLayout
        anyCss={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Img
          alt="logo"
          src="/logo.svg"
          anyCss={mediaQuery({ width: 60, height: 60, marginTop: [16, 24] })}
        />
        <HeaderNavigation
          anyCss={{
            minHeight: 70,
            padding: '16px 0',
            ul: {
              columnGap: 32,
              li: {
                color: options?.hasLightBackground
                  ? 'var(--color-stone-500)'
                  : ['var(--color-stone-500)', 'white'],
              },
            },
          }}
          model={{
            actionSegment: model?.actionSegment,
            menuSegment: model?.menuSegment,
            socialSegment: model?.socialSegment,
          }}
          subComponents={{ ...subComponents, TextLink: NavTextLink }}
        />
      </SectionLayout>
    </Div>
  )
}
