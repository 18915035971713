import { Icon, IconProps, StyleObject } from '@modelberry/blue-lib/react'

const iconStyle: StyleObject = {
  svg: {
    color: 'var(--color-fancygreen-400)',
    strokeWidth: 2,
  },
}

export const OrangeIcon = ({ anyCss, ...props }: IconProps) => (
  <Icon {...props} anyCss={[iconStyle, anyCss as any]} />
)
