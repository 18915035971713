import { ApolloClient, gql } from '@apollo/client'
import { apolloPageFragment } from '../cms/contentful-page'

export interface FetchPage {
  client: ApolloClient<any>
  pageId?: string
  preview?: boolean
}

export const fetchPage = async ({ client, pageId, preview }: FetchPage) => {
  const fullPage = await client.query({
    fetchPolicy: preview ? 'network-only' : 'cache-first',
    variables: { pageId, preview },
    query: gql`
      ${apolloPageFragment}
      query getPageDetails($pageId: String!, $preview: Boolean) {
        page(id: $pageId, preview: $preview) {
          ...Modelberry_Page
        }
      }
    `,
  })

  return fullPage?.data?.page
}
