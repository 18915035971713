const heroStyle: any = {
  // Content and actions
  '& > section': {
    alignItems: 'flex-end',
    textShadow: 'none',
    textAlign: 'right',
  },

  // Content: heading and abstract
  '& > section > section': {
    maxWidth: [350, 750],
    minHeight: [250, 400],
    justifyContent: 'center',
    '& > h1': {
      color: 'inherit',
      fontSize: ['var(--display-font-size-m)', 'var(--display-font-size-xl)'],
      fontWeight: 300,
    },
  },
}

export const topicVariantCss = {
  hero: heroStyle,
}
