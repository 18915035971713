import { useField } from 'formik'
import { AnyProps } from '@modelberry/any-element/react'
import { InputField, InputFieldVariant } from '@modelberry/blue-lib/react'

const getTextErrors = (requiredMessage?: string) => (value: string) => {
  let error = ''
  if (!value && requiredMessage) {
    error = requiredMessage
  }
  return error
}

export type TextFieldProps = {
  autoFocus?: boolean
  helpText?: string
  label?: string
  placeholder?: string
  requiredMessage?: string
  variant?: InputFieldVariant

  name: string
} & AnyProps['div']

export const TextField = ({
  autoFocus,
  helpText,
  label,
  name,
  placeholder,
  requiredMessage,
  variant,
  ...props
}: TextFieldProps) => {
  const [field, meta] = useField({
    name,
    validate: getTextErrors(requiredMessage),
  })

  const errors: string[] = []

  if (meta.error) errors.push(meta.error)

  return (
    <InputField
      model={{
        errorMessage: errors.length > 0 ? errors.join('/') : undefined,
        label,
        helpText,
        inputProps: {
          autoFocus,
          placeholder,
          ...field,
        },
      }}
      variant={variant || 'text'}
      {...props}
    />
  )
}
