import { gql } from '@apollo/client'

export const apolloAssetFragment: any = gql`
  fragment Modelberry_Asset on Asset {
    sys {
      id
    }
    contentType
    description
    fileName
    height
    size
    title
    url
    width
  }
`
