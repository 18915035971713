import { useField } from 'formik'
import {
  AnyProps,
  Label,
  Span,
  StyleObject,
} from '@modelberry/any-element/react'
import { Body, bodyStyleFactory, Textarea } from '@modelberry/blue-lib/react'

const getTextErrors = (requiredMessage?: string) => (value: string) => {
  let error = ''
  if (!value && requiredMessage) {
    error = requiredMessage
  }
  return error
}

const gapBoxStyle: StyleObject = { display: 'flex', flexDirection: 'column' }
const labelStyle = bodyStyleFactory({ variant: 's' })

export type TextareaFieldProps = {
  label?: string
  placeholder?: string
  name: string
  requiredMessage?: string
} & AnyProps['textarea']

export const TextareaField = ({
  label,
  name,
  placeholder,
  requiredMessage,
  ...props
}: TextareaFieldProps) => {
  const [field, meta] = useField({
    name,
    validate: getTextErrors(requiredMessage),
  })

  const errors: string[] = []
  if (meta.error) errors.push(meta.error)
  const errorMessage = errors.length > 0 ? errors.join('/') : undefined

  return (
    <Body anyCss={{ ...gapBoxStyle, gap: 4 }}>
      <Label htmlFor={name} anyCss={labelStyle}>
        {label}
      </Label>
      <Textarea
        {...field}
        name={name}
        placeholder={placeholder}
        anyCss={{ width: '100%', '&::placeholder': { color: 'var(--color-stone-300)' } }}
        {...props}
      />
      <Span anyCss={{ color: 'red', fontSize: 'var(--body-font-size-s)' }}>
        {errorMessage}
      </Span>
    </Body>
  )
}
