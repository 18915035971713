import {
  ComponentProps,
  ContentfulRichText,
  PageLayout,
  TopicSection,
} from '@modelberry/blue-lib/react'
import { getTopic } from '../lib/get-topic'
import { ContentfulPage } from '../cms/contentful-page'
import { topicVariantCss } from '../components/topic-variant-css'

export interface PageMain {
  page?: ContentfulPage
}

export type PageMainOption = 'preview' | 'hasLightBackground'
export type PageMainProps = ComponentProps<
  PageMain,
  string,
  PageMainOption
>['div']

export const PageMain = ({
  anyCss,
  model,
  options,
  subComponents,
  ...props
}: PageMainProps) => {
  const contentfulPage = model?.page
  const contentfulPageTopic = contentfulPage?.pageTopic
  const isHero = contentfulPageTopic?.variant === 'hero'
  const pageTopic = getTopic({
    contentfulTopic: contentfulPageTopic,
    useMetaDataFromPage: contentfulPage,
    preview: options?.preview,
  })

  topicVariantCss.hero['& > section > section']['& > h1'].color =
    options?.hasLightBackground ? 'var(--color-stone-500)' : 'white'
  return (
    <PageLayout
      is="main"
      anyCss={[isHero ? {} : { marginTop: [24, 48] }, anyCss as any]}
      {...props}
    >
      {contentfulPageTopic && (
        <TopicSection
          model={{ topics: [pageTopic] }}
          options={{
            reversedOrder: contentfulPageTopic?.reversedOrder,
            useTextColumnWidth: !contentfulPageTopic?.usePageWidth,
          }}
          subComponents={subComponents}
          topicVariant={contentfulPageTopic?.variant}
          topicVariantCss={topicVariantCss}
          variant="single"
        />
      )}
      {model?.page?.text && (
        <ContentfulRichText
          model={{ text: model?.page?.text }}
          options={{ preview: options?.preview }}
          subComponents={subComponents}
        />
      )}
    </PageLayout>
  )
}
