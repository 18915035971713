import { Div } from '@modelberry/any-element/react'
import {
  Button as ButtonComp,
  ComponentProps,
  MaxWidthSectionLayout,
  StyleObject,
} from '@modelberry/blue-lib/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { ContentfulContactForm } from './contentful-contact-form'
import { postNetlifyForm } from './post-netlify-form'
import { TextareaField } from './text-area-field'
import { TextField } from './text-field'

const gapBoxStyle: StyleObject = { display: 'flex', flexDirection: 'column' }

interface FormValues {
  name: string
  email: string
  checkbox: boolean
}

export interface ContactForm {
  contentfulContactForm?: ContentfulContactForm
}

export type ContactFormProps = ComponentProps<ContactForm>['div']

export const ContactForm = ({
  anyCss,
  model,
  subComponents,
  ...props
}: ContactFormProps) => {
  const [provideFeedback, setProvideFeedback] = useState(false)
  const initialValues: FormValues = {
    checkbox: false,
    email: '',
    name: '',
  }
  const formName = model?.contentfulContactForm?.variant || 'unknown'

  const Button = subComponents?.Button || ButtonComp

  return (
    <MaxWidthSectionLayout anyCss={anyCss} {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormValues) =>
          postNetlifyForm<FormValues>({
            formName,
            values,
            navigateTo:
              model?.contentfulContactForm?.navigateToPage?.path || '/',
          })
        }
        validateOnBlur={provideFeedback}
        validateOnChange={provideFeedback}
      >
        {() => (
          <Form
            name={formName}
            data-netlify="true"
            data-netlify-honeypot="name-field"
            style={{ width: '100%' }}
          >
            <Div anyCss={{ ...gapBoxStyle, gap: 32 }}>
              <Div anyCss={{ ...gapBoxStyle, gap: 16 }}>
                <input name="name-field" hidden />
                {model?.contentfulContactForm?.nameLabel && (
                  <TextField
                    label={model?.contentfulContactForm?.nameLabel}
                    name="name"
                    placeholder={model?.contentfulContactForm?.namePlaceholder}
                    requiredMessage={model?.contentfulContactForm?.nameRequired}
                    variant="text"
                  />
                )}
                {model?.contentfulContactForm?.emailLabel && (
                  <TextField
                    label={model?.contentfulContactForm?.emailLabel}
                    name="email"
                    placeholder={model?.contentfulContactForm?.emailPlaceholder}
                    requiredMessage={
                      model?.contentfulContactForm?.emailRequired
                    }
                    variant="email"
                  />
                )}
                {model?.contentfulContactForm?.messageLabel && (
                  <TextareaField
                    label={model?.contentfulContactForm?.messageLabel}
                    name="message"
                    placeholder={
                      model?.contentfulContactForm?.messagePlaceholder
                    }
                    requiredMessage={
                      model?.contentfulContactForm?.messageRequired
                    }
                  />
                )}
              </Div>
              <Button
                type="submit"
                variant="m"
                onClick={() => setProvideFeedback(true)}
              >
                {model?.contentfulContactForm?.sendButton}
              </Button>
            </Div>
          </Form>
        )}
      </Formik>
    </MaxWidthSectionLayout>
  )
}
