import { BlueTextLink } from './text-links'
import { OrangeIcon } from './icons'
import { GreenButton } from './buttons'
import { BlocksEmbeddedEntry } from './blocks-embedded-entry'
import { InlinesEntryHyperlink } from './inlines-entry-hyperlink'
import { StoneDisplay, StoneHeading } from './headings'
import { MdBody } from './body'

export const navigationSubComponents = {
  Button: GreenButton,
  TextLink: BlueTextLink,
}
export const richTextSubComponents = {
  BlocksEmbeddedEntry,
  Body: MdBody,
  Button: GreenButton,
  Display: StoneDisplay,
  Heading: StoneHeading,
  Icon: OrangeIcon,
  InlinesEntryHyperlink,
  TextLink: BlueTextLink,
}
