import { Div, Img } from '@modelberry/any-element/react'
import {
  Body,
  ComponentProps,
  Display,
  FooterNavigation,
  Heading,
  LegalNavigation,
  mediaQuery,
  Navigation,
  replaceNewlines,
  SectionLayout,
  Topic,
} from '@modelberry/blue-lib/react'
import { isBrowser } from '../lib/is-browser'
import { NavTextLink } from './text-links'

export interface PageFooter {
  brandingHeading?: string
  menuSegment?: Navigation
  socialSegment?: Navigation
  siteTopics?: Topic[]
  siteVersion?: string
}

export type FooterSectionProps = ComponentProps<PageFooter>['div']

export const FooterSection = ({
  anyCss,
  model,
  subComponents,
  ...props
}: FooterSectionProps) => {
  const socialCount = model?.socialSegment?.items?.length || 0
  const menuCount = model?.menuSegment?.items?.length || 0
  const hasFooter = socialCount + menuCount > 0
  const siteTopic1 = model?.siteTopics?.[0]
  const siteTopic2 = model?.siteTopics?.[1]
  return (
    <Div
      is="footer"
      anyCss={mediaQuery([{ marginTop: 80 }, anyCss as any])}
      {...props}
    >
      {hasFooter && (
        <Div
          anyCss={{
            backgroundColor: 'var(--color-fancygreen-400)',
            color: 'white',
            padding: '50px 18px',
          }}
        >
          <SectionLayout>
            <Div anyCss={{ display: 'flex', justifyContent: 'space-between' }}>
              <Display
                variant="m"
                anyCss={mediaQuery({
                  color: 'white',
                  fontSize: [
                    'var(--heading-font-size-xl)',
                    'var(--display-font-size-m)',
                  ],
                })}
              >
                {model?.brandingHeading}
              </Display>
              <Img
                alt="logo wit"
                src="/logo-white.svg"
                anyCss={mediaQuery({
                  width: [30, 90],
                  height: [30, 90],
                })}
              />
            </Div>
            <Div
              anyCss={mediaQuery({
                display: 'flex',
                flexDirection: ['column', 'row'],
              })}
            >
              <FooterNavigation
                anyCss={mediaQuery({
                  alignItems: 'flex-start',
                  ul: {
                    flexDirection: 'column',
                    padding: ['24px 8px', '0 8px'],
                    rowGap: 24,
                  },
                  width: ['100%', '50%'],
                })}
                model={{
                  menuSegment: model?.menuSegment,
                  socialSegment: model?.socialSegment,
                }}
                subComponents={{ ...subComponents, TextLink: NavTextLink }}
              />
              <Div
                anyCss={mediaQuery({
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  rowGap: 24,
                  width: ['100%', '50%'],
                })}
              >
                <Div>
                  <Heading
                    anyCss={{
                      color: 'white',
                      fontSize: [
                        'var(--heading-font-size-l)',
                        'var(--heading-font-size-xl)',
                      ],
                    }}
                    variant="xl"
                  >
                    {siteTopic1?.heading}
                  </Heading>
                  <Body anyCss={{ color: 'white' }} variant="m">
                    {replaceNewlines(siteTopic1?.abstract)}
                  </Body>
                </Div>
                <Div>
                  <Heading
                    anyCss={{
                      color: 'white',
                      fontSize: [
                        'var(--heading-font-size-l)',
                        'var(--heading-font-size-xl)',
                      ],
                    }}
                    variant="xl"
                  >
                    {siteTopic2?.heading}
                  </Heading>
                  <Body anyCss={{ color: 'white' }} variant="m">
                    {replaceNewlines(siteTopic2?.abstract)}
                  </Body>
                </Div>
              </Div>
            </Div>
          </SectionLayout>
        </Div>
      )}
      <Div
        anyCss={{
          borderTop: '1px solid var(--color-stone-100)',
        }}
      >
        <SectionLayout>
          <LegalNavigation
            anyCss={{ minHeight: 48, padding: '16px 0' }}
            model={{
              copyright: `Copyright 2021 © ${model?.brandingHeading}`,
              modelberryHeading: 'Made with Modelberry',
              modelberryVersion: model?.siteVersion,
              modelberryActionFn: () => {
                isBrowser &&
                  window.location.assign('https://www.modelberry.com')
              },
            }}
          />
        </SectionLayout>
      </Div>
    </Div>
  )
}
